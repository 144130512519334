import React, { useEffect } from 'react';
import {
  useLocation,
  useNavigation,
} from 'react-router-dom';
import {
  IoCodeSlash,
  IoRocket,
  IoShieldCheckmark,
  IoSparkles,
  IoToggle,
} from 'react-icons/io5';
import { TbApi } from 'react-icons/tb';
import Logo from '../components/Logo';
import Footer from '../components/Footer';
import Header from '../components/Header';

export default function Index() {
  const location = useLocation();
  const navigation = useNavigation();

  useEffect(() => {
    if (!location.hash) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      return;
    }
    const element = document.getElementById(location.hash.slice(1));
    if (element) {
      window.scrollTo({
        top: element.offsetTop,
        behavior: 'smooth',
      });
    }
  }, [location.hash]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div
      id="home"
      className={navigation.state === "loading" ? "loading" : ""}
      > 
      <Header linksShown />
      <div className='hero'>
        <div className='frame-hero'>
          <div className='bigText'>
            <div>
              Eliminating the 
            </div>            
            <div className='noise'>
              <span className='biggerText'>
                noise
              </span>
            </div>
            <div>
              from Law Enforcement
            </div>
            <div>
              Requests
            </div>
            <div className='sellPoints'>
              <div className='smallText'>
                <b>No more typos</b>
                Verify that you're sending law enforcement the right data.
              </div>
              <div className='smallText'>
                <b>Save countless hours</b> Convert law enforcement requests into actionable data, eliminating manual entry.
              </div>
            </div>
          </div>
          <div className='description inline topBlurb'>
            <div style={{marginLeft: 50}}>
              <Logo inline/> transforms law enforcement
            </div>
            <div>
              requests into standardized, machine-readable
            </div>
            <div>
              formats customized to your organization.
            </div>
          </div>
        </div>
      </div>
      <div className='features' id='solution'>
          <h1>How does it work?</h1>
          <div className='steps'>
            <div className='step'>
              <h2>1. Define a Schema</h2>
              First, define what data you are able to provide to law enforcement.
              You can also upload a sample "download my data" request and our AI will extract the relevant fields.
            </div>
            <div className='step'>
              <h2>2. Upload Requests</h2>
              Upload your law enforcement requests.
              Our AI compares the request against your schema to figure out what data is being requested over what time range.
            </div>
            <div className='step'>
              <h2>3. Receive Standardized Data</h2>
              Receive a callback from Warranted or download the results including a human readable explanation of why we believe each field is requested or not.
            </div>
          </div>
          <div className='highlights' id='features'>
          <h1 className='highlightsTitle'>
            Notable Features
          </h1>
          <div className='highlightsTable'>
            <div className='cell'>
              <IoRocket />
              AI powered conversion of law enforcement requests to actionable data
            </div>
            <div className='cell'>
              <IoShieldCheckmark />
              All data is encrypted on Warranted Servers
            </div>
            <div className='cell'>
              <IoToggle />
              Data retention controls
            </div>
            <div className='cell'>
              <TbApi />
              Automated callbacks. API first.
            </div>
            <div className='cell'>
              <IoCodeSlash />
              Client libraries for easy integration
            </div>
            <div className='cell'>
              <IoSparkles />
              Roadmap of new features to streamline your law enforcement operations
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}