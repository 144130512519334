import React from 'react';
import {
  Link,
} from 'react-router-dom';
import Logo from './Logo';

export default function Header({linksShown = false}) {

  return (
        <div className='header'>
            <Link to='/' className='logoLink'>
                <Logo inline />
            </Link>
            <div className='links'>
                { linksShown ? (
                    <>
                        <Link to='#solution' className='link desktop'>Solution</Link>
                        <Link to='#features' className='link desktop'>Features</Link>
                    </>
                ) : null }
                <Link to='https://app.warranted.io/createAccount' className='demo'>
                    <button className='button primary'>
                        Try it now
                    </button>
                </Link>
                <Link to='https://app.warranted.io/'>
                    <button className='button flat'>
                        Log in
                    </button>
                </Link>
            </div>
        </div>
    );
}