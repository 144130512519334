import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';
import './index.css';
import Index from "./routes";
import ErrorPage from './ErrorPage';
import reportWebVitals from './reportWebVitals';
import NotFound from './routes/notFound';
import Privacy from './routes/Privacy';
import Terms from './routes/Terms';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Index />,
    errorElement: <ErrorPage />,
  }, {
    path: '/terms',
    element: <Terms />,
    errorElement: <ErrorPage />,
  }, 
  {
    path: '/privacy-policy',
    element: <Privacy />,
    errorElement: <ErrorPage />,
  }, {
    path: '*',
    element: <NotFound />,
    errorElement: <ErrorPage />,
  }
]);

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
