import React from 'react';

export default function Logo({inline=false, light=false}) {

  return (
    <div className={`logo ${inline ? 'inline' : ''} ${light ? 'light' : ''}`}>
      <span className='logo-w'>
        <img src={light ? '/images/logo-light.svg' : '/images/logo.svg'} alt='W' />
      </span>
      arranted
    </div>
  );
}