import React, { useEffect } from 'react';
import {
  Link,
  useLocation,
  useNavigation,
} from 'react-router-dom';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Logo from '../components/Logo';

export default function Privacy() {
  const navigation = useNavigation();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div
      id="home"
      className={navigation.state === "loading" ? "loading" : ""}
    >
      <Header />
      <div id='legal'>
        <center>
          <Link to='/' className='link'>
            <Logo />
          </Link>
        </center>
        <p>
          <strong>PRIVACY AND COOKIES POLICY</strong>
        </p>
        <p>
          Effective Date: May 20, 2024
        </p>
        <p>
          Welcome to Warranted LLC. (hereinafter Warranted,” “we” or “us” ) website at https://www.warranted.io, the website at https://app.warranted.io, and certain other sites operated by Warranted where these Terms of Use are posted (including all content posted at those sites) is owned by Warranted LLC., and each is referred to herein as the “ Website .” The services that we may provide to you via the Website or any mobile or desktop application (“ App ”), as well as the Website and App itself, are referred to collectively herein as the “ Warranted Services .” In general terms, the Warranted Services provide to registered users a platform (the “ Warranted Platform ”) which enables the exchange of information between Business Users and Government Users, as such terms are defined below.
        </p>
        <p>
          This notice (“ Privacy Policy “) supplements and is included as a part of our <a className='link' href="mailto:dataprotection@warranted.io">Terms of </a><a className='link' href="mailto:dataprotection@warranted.io">Service</a>. By accepting the Terms of Service in connection with the Warranted Services, you also agree to this Privacy Policy.
        </p>
        <p>
          This Privacy Policy explains our online information collection and use practices, and the choices you can make about the way we use such information. Please take the time to read and understand this Privacy Policy so that you can appreciate how we use your Personal Data (as defined below). <strong>AS WE UPDATE THE WEBSITE OR APP OR EXPAND OTHER WARRANTED SERVICES, WE MAY CHANGE THIS PRIVACY POLICY UPON NOTICE TO YOU, HOWEVER, PLEASE REVIEW IT FROM TIME TO TIME. </strong>This Privacy Policy is subject to the provisions of applicable data protection and privacy laws.
        </p>
        <p>
          <em>For EEA / UK and Swiss residents:</em>
        </p>
        <p>
          <br />
          <em>
            If you are resident in the European Economic Area (“EEA”) / United Kingdom (“UK”) / Switzerland, we will comply with the EU and UK General Data Protection Regulations (“GDPR”, as applicable) and/or other applicable data protection and privacy laws in the EEA or UK. For the purposes of the GDPR, if you are a Visitor or a Government User without a separate written contract with us providing other terms, then with respect to the Warranted Services, Warranted is a data “Controller” and you are a “Data Subject” with certain protected privacy rights concerning your “Personal Data.”
          </em>
        </p>
        <p>
          <br />
          <em>
            If you are a Business User, Warranted will be a data “Processor” and your organization that is contracting for our Warranted Services will be the data “Controller.”
          </em>
        </p>
        <p>
          <br />
          <em>
            We do not control any third-party websites or the requests or responses to requests for information and are not responsible for their privacy statements or practices.
          </em>
        </p>
        <p>
          <br />
          <em>
            “Personal Data” in this Privacy Policy means any information relating to you as a natural person that can be directly or indirectly related to you. Personal Data includes name, email address, ID number, location, household information, online ID, and factors specific to your physical, physiological, genetic, mental, economic, cultural or social identity. Your Personal Data may identify you as a person, and thus is often referred to as Personally Identifiable Information (“PII”) or Personal Information (“PI”). Both PII and PI are included in the term “Personal Data” as used in this Privacy Policy.
          </em>
        </p>
        <p>
          As used in this Agreement, “ you” :
        </p>
        <p>
          <br />are a “ <strong>Visitor</strong> ” to or when you browse our Website or App without creating a Business
        </p>
        <p>
          Account or Government Account; or
        </p>
        <p>
          <br />are a “ <strong>Business User</strong> ” who uses our Services on behalf of a Business with whom Warranted has a
        </p>
        <p>
          commercial relationship (the “ <strong>Business Services </strong>”); or
        </p>
        <p>
          <br />are a “ <strong>Government User</strong> ” who uses our Services on behalf of a police department,
        </p>
        <p>
          government administrative or investigative body, legislative or parliamentary body, or other
        </p>
        <p>
          agency of a national, regional, state/provincial, or municipal authority or body (the
        </p>
        <p>
          “ <strong>Government Services </strong>”).
        </p>
        <p>
          <br/>Different provisions of these terms will apply to you dependent on if you are a Visitor or a
        </p>
        <p>
          Business or Government User. When we use “you” without expressly stating if this applies to
        </p>
        <p>
          <br />Visitors or Business or Government Users, then that the provision applies to everyone.
        </p>
        <p>
          <br />
          <strong>1. Who Collects Your Information On Our Website?</strong>
        </p>
        <p>
          We do. We collect information from you on the Website, <strong>and we are responsible for</strong> <strong>protection of your information.</strong>
        </p>
        <p>
          We have appointed a Data Protection Officer (“DPO”) to manage our privacy obligations. If you have any questions about this Privacy Policy, including any requests to exercise your legal rights, please contact our Data Protection Officer by emailing <a className='link' href="mailto:dataprotection@warranted.io">dataprotection@warranted.io</a>.
        </p>
        <p>
          <strong>2. What Information Does Warranted Collect?</strong>
        </p>
        <p>
          <strong>A. Provided Information.</strong> On various pages on the Website or App, you may provide specific Personal Data about yourself in order to register for an account to use our Services, be added to our email list, and so that we may facilitate delivery of our Servicesto you. Please note that payment for Warranted Services is required only for Business Users, who will also be required to provide financial information for their organizations, not as individuals, as we do not accept payment from individual accounts. You may choose not to provide your Personal Data, but then you will not be able to take advantage of some of the features of our Website, App, or other Warranted Services. We do not collect any Special Category Data (this includes details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about your health, and genetic and biometric data) as defined in the GDPR. Nor do we collect any information about your criminal convictions and/or offenses.
        </p>
        <p>
          <em>The types of Personal Data we collect include:</em>
        </p>
        <p>
          ● Contact and account registration information such as name, email address, physical address, and phone number;
        </p>
        <p>
          ● Financial information such as bank or credit card information, details about payments to and from you;
        </p>
        <p>
          ● Transactional information such as details of Services you have purchased or otherwise obtained from us;
        </p>
        <p>
        ● Communications Data including your preferences in receiving information from us;
        </p>
        <p>
          ● Information you provide such as feedback, comments or other messages; and
        </p>
        <p>
          ● Technical and usage information , which information may include standard web log entries that contain your IP address, page URL, browser plug-in types and versions, device information and identifier, operating system information, and timestamp.
        </p>
        <p>
          <strong>In addition, we provide the Warranted Portal for Business Users and Government</strong>
        </p>
        <p>
          <strong>Users to exchange communications about third parties</strong>,<strong> </strong>such as information about Business User customers or any targets of investigation or law enforcement, their addresses, financial or transactional information, and similar content Communications exchanged by Business Users and Government Users may include Personal Data about those third parties. The content of those communications about third parties is not used by Warranted for any purposes other than facilitation of the exchange between the Business User and the Government User, as directed by the Business User.
        </p>
        <p>
          <strong>B. Anonymous and Aggregate Information.</strong> We may also collect anonymous information such as the type of browser you are using, device type, the operating system you are using, and the identity of your Internet service provider. Aggregated data may be derived from your Personal Data, but is not itself Personal Data and it cannot be used, either on its own or together with other data, to directly or indirectly reveal your identity.
        </p>
        <p>
          <strong>3. Why Is My Personal Data Being Collected?</strong>
        </p>
        <p>
          We need to collect your Personal Data so that we can provide the Warranted Services, which may include responses to your inquiries about or demonstrations of the Warranted Platform, or to add you to our emailing lists, and to provide account registrations and account services, or to process payment for Warranted Services. We also collect aggregate information to help us better design and make improvements to the Warranted Services, including the Website, Apps, and Warranted Platform. We collect log information for monitoring purposes to help us to diagnose technical problems, administer the Website and App, calculate usage levels, for product assessment and improvement, and otherwise to provide services to you. We use commercially reasonable efforts to verify the location and identity of Government Users. Further details on why your Personal Data are being collected and our legal basis for doing so under GDPR (for EEA/UK/Swiss residents) are provided in Section 15, below.
        </p>
        <p>
          <strong>4. How Do We Use the Information We Collect?</strong>
        </p>
        <p>
          <strong>A.</strong> We use the Personal Data you provide for the purposes for which you have submitted it including:<br /><br />● <strong>Responding To Your Inquiries, Feedback and Providing the Warranted Services.</strong> We may use your Personal Data to respond to your inquiries and feedback, and to provide the Warranted Service.
        </p>
        <p>
          ● <strong>Creating and Maintaining Your User Account.</strong> We use your Personal Data to create and maintain an account for you to allow you to use the Warranted Services we make available on or through the Website or App.
        </p>
        <p>
          ● <strong>Security and Fraud Detection.</strong> We use your Personal Data along with various fraud detection methods to attempt to confirm the location and identity of Business Users and Government Users.
        </p>
        <p>
          ●<strong> Subscribing To and Paying For Our Services.</strong> We use your Personal Data to add Business User subscriptions to our Services, and process your payment for these Services.
        </p>
        <p>
          ● <strong>Communicating With You About Our Services.</strong> We may use your Personal Data to send you marketing information about new Services and other items that may be of interest to you.
        </p>
        <p>
          ●<strong> Sending Administrative Messages.</strong> We may use your Personal Data to call, text, or send you email or other messages to: (a) confirm your identity, your account and your other Personal Data, (b) process your transactions, (c) provide you with information regarding the Warranted Services, or (d) inform you of changes to this Privacy Policy, our Terms of Service, or our other policies.
        </p>
        <p>
          <strong>B.</strong> We may use anonymous information that we collect to improve the design and content of our Website and App We also may use this anonymous information to analyze how Warranted Services are used, to analyze industry trends, as well as to enable us to offer new programs or new or enhanced services.
        </p>
        <p>
          <strong>5. What Are Your Choices Regarding Our Marketing Materials?</strong>
        </p>
        <p>
          We strive to provide you with choices regarding certain Personal Data uses, particularly around marketing and any advertising. When required under law (including the GDPR), we will obtain your consent to send you marketing materials. In general, we will provide a button, checkbox or other means where you affirmatively opt-in to receive marketing emails or other similar communications from us. You can change your preferences or ask us to stop sending you marketing messages and/or remove your email from our mailing lists by contacting us at <a className='link' href="mailto:dataprotection@warranted.io">dataprotection@warranted.io</a>, or by following the instructions within the marketing messages that you have received.
        </p>
        <p>
          <br /><strong>6. Do We Share Your Personal Data?</strong>
        </p>
        <p>
          In general, we will not share your Personal Data except:<br />(a) for the purposes for which you provided it;<br />(b) with your consent;<br />(c) as may be required by law, for example with our professional advisors, lawyers, insurers, regulators and other authorities, or as we think necessary to protect our organization or others from injury (e.g., in response to a court order or subpoena, in response to a law enforcement agency request, or when we believe that someone is causing, or is about to cause, injury to or interference with our rights or property, or with the rights or property of another person or entity);<br />(d) on a confidential basis with persons or organizations with whom we contract, such as our suppliers, to carry out internal operations or as necessary to render the Services;<br />(e) if you are a Business User or a Government User, to confirm your identity and your right to use our Services; or<br />(f) with our business partners only in compliance with relevant data protection laws. We may also share aggregate anonymous information with others, including affiliated and non-affiliated organizations.</p><p>Finally, we may transfer your Personal Data to our successor-in-interest in the event of an acquisition, sale, merger or bankruptcy. If a change happens to our business, then the new owners may use your Personal Data in the same way as set out in this Privacy Policy.
        </p>
        <p>
          <strong>7. What Categories of Personal Data Do We Share?</strong>
        </p>
        <p>
          We share only the categories of Personal Data required for us to carry out our internal operations or as necessary to render Warranted Services. These categories include your contact and registration information, financial and transaction information, and your technical and Warranted Service usage information. As discussed above, the categories of third parties with whom we may share such Personal Data include our service providers, suppliers, business partners, affiliates and professional advisors, as well as law enforcement.
        </p>
        <p>
          <br/><strong>8. Are There Other Ways My Personal Data Could be Shared?</strong>
        </p>
        <p>
          You may elect to share certain Personal Data with other users via your use of the Warranted Services. In this case, you will control such sharing. For example, the Warranted Service features the ability for Government Users to communicate with Business Users about targets of law enforcement and administrative actions. Be aware that when you choose to share any information online with anyone, including friends, public officials, or the public at large, you may be disclosing sensitive information, or information from which sensitive information can be inferred. Always use caution when sharing information through the internet. You understand and agree that Warranted is not responsible for any consequences of your sharing of information through and beyond the Warranted Services. We do not control any other third-party websites or activities and are not responsible for their privacy policies or operations. When you leave our Website or App, we encourage you to read the privacy policy of every website or app you visit or use.
        </p>
        <p>
          <strong>9. How Can You Access and Control Your Information?</strong>
        </p>
        <p>
          After registering for an account on the Website or App, you may log-in to the account and edit&nbsp; your Personal Data in your profile.&nbsp; For instructions on how you can further access your&nbsp; Personal Data that we have collected, or how to correct errors or inaccuracies in such&nbsp; information, please send an e-mail to &nbsp; <a className='link' href="mailto:dataprotection@warranted.io">dataprotection@warranted.io</a>.&nbsp;&nbsp;
        </p>
        <p>
          To protect your privacy and security, we will take reasonable steps to help verify your identity&nbsp; before granting access, making corrections or removing your information.&nbsp; Again, please send&nbsp; an e-mail to&nbsp; <a className='link' href="mailto:dataprotection@warranted.io">dataprotection@warranted.io</a>&nbsp; to make&nbsp; your request.&nbsp; Please note that if you&nbsp; request removal, we will stop using your Personal Information and will remove it from our&nbsp; servers&nbsp; (unless we have a legitimate reason to keep&nbsp; that information in light of the purpose for&nbsp; which it was provided). Further, we reserve the right to retain Personal Information and other&nbsp; data about persons or entities who make false or fraudulent statements to us, for who abuse or&nbsp; attempt to abuse the Warranted Services, and we may submit that data to appropriate law&nbsp; enforcement authorities.&nbsp;
        </p>
        <p>
          <strong>10. How Do We Store and Protect Your Information?</strong>
        </p>
        <p>
          <strong>A.</strong> &nbsp; After receiving your Personal Data, we will store it on our systems for future use. We have physical, electronic, and managerial procedures in place to safeguard and help prevent unauthorized access, maintain data security, and reasonably use the information we collect. Unfortunately, no data transmission over the Internet or data storage solution can ever be completely secure. As a result, although we take industry-standard steps to protect your information (e.g., strong encryption), we cannot ensure or warrant the security of any information you transmit to or receive from us or that we store on our or our service providers’ systems.
        </p>
        <p>
          <strong>B</strong>.&nbsp; If you are visiting the Website or using the App from outside of the USA, you understand that your connection will be through and to servers located in the USA, and the information you provide will be transferred outside your local location (e.g., the EEA/UK), and will be securely stored in our web servers and internal systems located within the USA.
        </p>
        <p>
          <strong>C.</strong>&nbsp; For EEA/UK/Swiss citizens : Whenever we transfer your Personal Data outside of the EEA, Switzerland or UK, we ensure a degree of protection is afforded to it that is similar to that of the EEA, Switzerland or UK by ensuring at least one of the following safeguards is implemented:
        </p>
        <p>
          ● We will only transfer your Personal Data to countries that have been deemed to provide an adequate level of protection for Personal Data under EEA or UK laws; or
        </p>
        <p>
          ● Where we use certain service providers, we may use specific contracts approved under
        </p>
        <p>
          <strong>D.&nbsp; </strong>We will only retain your Personal Data for as long as is reasonably necessary to fulfill the purposes for which we collected it, including for the purposes of satisfying any legal, regulatory, tax, accounting or reporting requirements. We may retain your Personal Data for a longer period in the event of a complaint, we believe there has been fraud or abuse of Warranted Services, or if we reasonably believe there is a prospect of litigation in respect to our relationship with you.
        </p>
        <p>
          <strong>E.&nbsp; </strong>We store our logs and other technical records indefinitely (or where they contain personal data, for as long as we have a legitimate reason to keep that information in light of the purpose for which it was provided).
        </p>
        <p>
          <strong>F.&nbsp; </strong>Content of communications between users is retained only for the period designated by the Business User<em>.</em>
        </p>
        <p>
          <strong><br /></strong><strong>11. Cookie Policy: How Do We Use Cookies And Other Network Technologies?</strong>
        </p>
        <p>
          <strong>A.&nbsp; </strong>To enhance your online experience with us, our Website may presently or in the future use&nbsp; “cookies.” Cookies are text files that our web server may place on your hard disk to store your preferences. We may use session, persistent, first-party and third-party cookies. Cookies, by themselves, do not tell us your e-mail address or other Personal Data unless you choose to provide this information to us. Once you choose to provide Personal Data, however, this information may be linked to the data stored in a cookie. We have summarized the types of cookies in Section 11.E, below.
        </p>
        <p>
          <strong>B.</strong>&nbsp; We may also use browser functionality such as LocalStorage to identify users, secure the Warranted Platform, and engage in product improvement and analytics. LocalStorage is not a cookie, but causes your browser to retain data. We encrypt this data when it is in transit.
        </p>
        <p>
          <strong>C.</strong>&nbsp; We or our service providers may also use “pixel tags,” “web beacons,” “clear GIFs” embedded links, and other commonly used information-gathering tools in connection with some pages on our Website and HTML-formatted email messages for such purposes as compiling aggregate statistics about Website usage and response rates. A pixel tag is an electronic image (often a single pixel), that is ordinarily not visible to website visitors, and may be associated with cookies on visitors’ hard drives. Pixel tags allow us and our service providers to count users who have visited certain pages of our Website, to deliver customized services, and to help determine the effectiveness of our Website and Warranted Services. When used in HTML-formatted email messages, pixel tags can inform the sender of the email whether and when the email has been opened.
        </p>
        <p>
          <strong>D. </strong>&nbsp;As you use the Internet, you leave a trail of electronic information at each website you visit. This information, which is sometimes referred to as “Visitor Data”, can be collected and stored by a website’s server. Visitor Data can reveal the type of computer and browsing software you use and the address of the website from which you linked to our Website. We may use Visitor Data as a form of non-Personal Data to determine how much time visitors spend on each page of our Website, how visitors navigate through our Website, and how we may tailor our web pages to better meet the needs of visitors. We will only use this information to&nbsp; improve Warranted Services.<br /><br /><strong>E.&nbsp; </strong>The types of cookies that we may use in providing the Warranted Services include the following:<strong><br />● </strong>Strictly necessary cookies. These are cookies that are required for the operation of our Website or the Warranted Platform. They include, for example, cookies that enable you to log into secure areas of the Warranted Platform, or make use of e-billing services.
        </p>
        <p>
          <strong>● </strong>Analytical/performance cookies. These cookies allow us to recognize and count the number of visitors and to see how visitors move around our Website or other Warranted Services when they are using it. This helps us to improve the way our Website works, for example, by assisting users in easily finding what they are looking for.
        </p>
        <p>
          <strong>● </strong>Functionality cookies. These cookies are used to recognize you when you return to our
        </p>
        <p>
          Website or to the Warranted Platform. This enables us to personalize our content for you, greet you by name and remember your preferences (for example, your choice of language or region).
        </p>
        <p>
          <strong><br /></strong><strong>F.&nbsp; Our Use of Cookies. </strong> Please note that the number and names of cookies may change, and the Cookies Page may be updated from time to time to provide you with the latest information.
        </p>
        <p>
          <strong>G. </strong>How To Manage Cookies
        </p>
        <p>
          You may manage, disable and remove cookies from your computer using the following methods:
        </p>
        <p>
          (1) Modify your browser settings:
        </p>
        <p>
          ● Please follow the instructions provided by your browser to manage, disable or remove cookies.
        </p>
        <p>
          ● Please be careful in making your choices. By blocking the receipt of all cookies indiscriminately, including the technical ones, without providing a specific exception for the Website, you may no longer able to surf the Website or to benefit, in whole or in part, from its functionalities. Furthermore, removing the technical cookies could remove the preferences set up by using the Website so that you could no longer find products or services you expect to see.
        </p>
        <p>
          (2) Use our interactive banner:<br />● In order to disable first party profiling cookies and/or identifiers (i.e., those cookies that we directly install), you can simply edit your preferences using the interactive banner that is displayed on our Website homepage. Your choice to disable the use of these cookies will not have any consequences on your ability to surf the Website or use its functionalities. You may reinstate cookie functionality at any time.
        </p>
        <p>
          By clicking “Accept” on the cookie consent box on our interactive banner when you first access our Website, you accept our use of cookies.<br /><br />In order to disable the third parties cookies or identifiers, please see the privacy policies of those third parties.
        </p>
        <p>
          <strong>12. Collection of Information by Others</strong><strong><br /></strong><strong><br /></strong>The Warranted Services may include links that take you to certain third party websites. Please check the privacy policies of these other websites to learn how they collect, use, store and share information that you may submit to them or that they collect.
        </p>
        <p>
          <strong>13. Children and Young People’s Information</strong><br /><strong><br /></strong>We do not knowingly collect any information from any minors, and we comply with all applicable privacy laws including the GDPR, CCPA, USA Children’s Online Privacy Protection Act (“COPPA”) and associated Federal Trade Commission (“FTC”) rules for collecting Personal Data from minors. Please see the FTC’s website (www.ftc.gov) for more information. If you have concerns about this Website or Warranted Services, wish to find out if your child has accessed our Services, or wish to remove your child’s Personal Data from our servers, please contact us at <a className='link' href="mailto:dataprotection@warranted.io">dataprotection@warranted.io</a>. Our Website will not knowingly accept Personal Data from anyone under 13 years old without consent of a parent or guardian. In the event that we discover that a child under the age of 13 has provided Personal Data to us, we will make efforts to delete the child’s information in accordance with the COPPA. If you believe that your child under 13 has gained access to our Website without your permission, please contact us at <a className='link' href="mailto:dataprotection@warranted.io">dataprotection@warranted.io</a>.<br /><br /><strong>14. California Privacy Rights; Virginia Privacy Rights</strong>
        </p>
        <p>
          <strong>A. California.</strong> The California Consumer Privacy Act (as amended, the “CCPA”) enhances privacy rights and consumer protection for residents of California. Under the CCPA, California residents have the rights to: 1) know what Personal Data are being collected about them in the preceding twelve (12) months; 2) know if their Personal Data are sold or disclosed in the preceding twelve (12) months, and to whom; 3) say ‘no’ to the sale of their Personal Data; 4) access and request deletion of their Personal Data; and 5) receive equal service and price from businesses, even after exercising the foregoing privacy rights. California law allows California residents to request information regarding our disclosures in the preceding twelve (12) months, if any, of their Personal Data to third parties. We do not sell Personal Data to third parties and therefore it is not necessary to opt-out of such a sale. To request access or deletion of your Personal Data, please contact us <a className='link' href="mailto:dataprotection@warranted.io">dataprotection@warranted.io</a> with “Request for Privacy Information” in the subject line. Please include enough detail for us to locate your file to include your name, email address, and username, if any. After verifying your identity, we will provide you with the requested information within forty-five (45) days of receipt. We reserve our right not to respond to requests submitted to an address other than the one posted in this notice. Please note that the CCPA law does not cover all information sharing. Our disclosure only includes information covered by the CCPA.
        </p>
        <p>
          <strong>B.&nbsp; Other States. </strong>We do not sell Personal Data to third parties. If you believe you have additional rights to your Personal Data under state law other than that of California, and you wish to exercise those rights, please contact us dataprotection@warranted.io with “Request for Privacy Information” in the subject line. Please include enough detail for us to locate your file to include your name, email address, physical address and username, if any, together with your specific request. After verifying your identity, we will provide you with the requested information within the period required by the law applicable to you.
        </p>
        <p>
          <strong>15. For EEA/Switzerland / UK Residents</strong>
        </p>
        <p>
          <strong>A. Your Legal Rights</strong>
        </p>
        <p>
          If you are an EEA/Switzerland/UK resident, under the data protection laws that apply to you, you may contact us at dataprotection@warranted.io to exercise your rights to:
        </p>
        <p>
          ● <strong>Request access</strong> to your Personal Data (commonly known as a “data subject access request”). This enables you to receive a copy of the Personal Data we hold about you and to check that we are lawfully processing it.
        </p>
        <p>
          ● <strong>Request corrections</strong> of the Personal Data that we hold about you. This enables you to have any incomplete or inaccurate data we hold about you corrected, though we may need to verify the accuracy of any new data you provide to us.
        </p>
        <p>
          ● <strong>Request erasure</strong> of your Personal Data. This enables you to ask us to delete or remove Personal Data where there is no legal basis for us to continue to process it. You also have the right to ask us to delete or remove your Personal Data where you have successfully exercised your right to object to processing (see below), where we may have processed your information unlawfully or where we are required to erase your Personal Data to comply with local law. Note, however, that we may not always be able to comply with your request of erasure for specific legal reasons of which we will notify you, if applicable, at the time of your request.
        </p>
        <p>
          ● <strong>Object to processing</strong> of your Personal Data where we are relying on a legitimate interest (or those of a third party), and you object to this processing as you feel it impacts your fundamental rights and freedoms. You may also object to our processing your Personal Data for direct marketing purposes. In some cases, we may deny your objection if we can demonstrate that we have compelling legitimate grounds to process your information which override your rights and freedoms.
        </p>
        <p>
          ● <strong>Request restriction of processing</strong> of your Personal Data. You may request us to suspend the processing your Personal Data in the following scenarios:
        </p>
        <p>
          ● If you want us to establish the data’s accuracy.
        </p>
        <p>
          ● Where our use of the data is unlawful but you do not want us to erase it.
        </p>
        <p>
          ● Where you need us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims.
        </p>
        <p>
          ● You have objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it.
        </p>
        <p>
          ● <strong>Request the transfer</strong> of your Personal Data to you or to a third party. Upon request, we will provide to you, or a third party you have chosen, your Personal Data in a structured, commonly used, machine-readable format. Note that this right only applies to automated information that you initially consented for us to use, or that we used in performing our obligations under a contract with you.
        </p>
        <p>
          ● <strong>Withdraw consent at any time</strong> where we are relying on consent to process your Personal Data. This withdrawal will not, however, affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide certain products or services to you. We will advise you if this is the case at the time you withdraw your consent.
        </p>
        <p>
          ● <strong>Make a complaint to a regulator</strong>. You have the right to make a complaint at any time to your local supervisory authority with regard to data protection issues. We would, however, appreciate the chance to deal with your concerns before you approach any supervisory authority, so please contact us at dataprotection@warranted.io in the first instance.
        </p>
        <p>
          <strong>B. Purposes / Legal Basis For Which We Will Use Your Personal Data</strong>
        </p>
        <p>
          For EEA/Swiss/UK residents, data protection laws require us to explain to you our legal basis for using your Personal Data. Most commonly, we will use your Personal Data under the following legal bases:
        </p>
        <p>
          ● Where it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests;
        </p>
        <p>
          ● Where we need to perform our obligations under a contract we are about to enter into, or have entered into with you; and
        </p>
        <p>
          ● Where we need to comply with a legal obligation. Generally, we do not rely on consent as a legal basis for processing your Personal Data although we will get your consent before sending direct marketing communications to you via email if we are contacting you using details other than your corporate email address. You have the right to withdraw consent to marketing at any time by contacting us.
        </p>
        <figure className='wp-block-table'>
          <table>
            <tbody>
              <tr>
                <td>
                  <strong>Purpose/Activity Type of Personal Data Lawful basis for processing</strong></td><td><strong>Purpose/Activity Type of Personal Data Lawful basis for processing</strong></td><td><strong>Purpose/Activity Type of Personal Data Lawful basis for processing</strong>
                </td>
              </tr>
              <tr>
                <td>
                  To register you as a new user, to confirm your identity, and to create and maintain your account
                </td>
                <td>
                  Name, email address, address, username,password, date of birth, and phone number, employer and/or law enforcement credentials</td><td>Performance of a contract
                </td>
              </tr>
              <tr>
                <td>
                  To process and deliver Warranted Services including:(a) Manage payments, fees and charges, if you are a Business User; and(b) Collect and recover money owed to us
                </td>
                <td>
                  Name, email address, address, payment details and transaction history.
                </td>
                <td>
                  Performance of a contractNecessary for our legitimate interests (to recover debts due to us)
                </td>
              </tr>
              <tr>
                <td>
                  To manage our relationship with you which will include:(a) Notifying you about changes to our terms or Privacy Policy(b) Asking you to provide feedback
                </td>
                <td>
                  Name, email address, address, payment details and transaction history.
                </td>
                <td>
                  Necessary to comply with a legal obligation<br />Necessary for our legitimate interests (to keep our records updated and to study how the Warranted Services are used 
                </td>
              </tr>
              <tr>
                <td>
                  To deal with any enquiries, correspondence, concerns or complaints you have raised</td><td>Name, username, email address, and information about the issue raised
                </td>
                <td>
                  Legitimate interests – to allow us to respond and deal with any queries, correspondence, concerns or complaints raised by you
                </td>
              </tr>
              <tr>
                <td>
                  To administer and protect our business and the Warranted Services (includingtroubleshooting, data analysis, testing, system maintenance, support, reporting and hosting of data)
                </td>
                <td>
                  Name, username, email address, standard web log entries that contain your IP address, page URL, browser plug-in types and versions, device information and identifier, operating system information, and timestamp
                </td>
                <td>
                  Necessary for our legitimate interests (for running our business, provision ofadministration and IT services, network security, to prevent fraud and in the context of a business reorganization or grouprestructuring exercise)<br /><br />Necessary to comply with a legal obligation
                </td>
              </tr>
              <tr>
                <td>
                  To deliver relevant Warranted Service content and other materials to you and measureor understand the effectiveness of the materials we serve to you
                </td>
                <td>
                  Name, username, email address, standard web log entries that contain your IP address, page URL, browser plug-in types and versions, device information and identifier, operating system information, and timestamp
                </td>
                <td>
                  Consent, when required&nbsp;<br />Necessary for our legitimate interests in providing Warranted Services and providinginformation about our business and Warranted Service enhancements
                </td>
              </tr>
              <tr>
                <td>
                  To use data analytics to improve Warranted Services and the user experience and users’ relationships with us, , and to improve marketing of new or improved Warranted Services to existing users
                </td>
                <td>
                  Name, username, email address, standard web log entries that contain your IPaddress, page URL, browser plug-in types and versions, device information and identifier, operating system information, and timestamp
                </td>
                <td>
                  Necessary for our legitimate interests (to define types of customers for our products and Services, to keep Warranted Servicesupdated and relevant, to develop our business and to inform our marketing strategy)
                </td>
              </tr>
              <tr>
                <td>
                  To make suggestions and recommendations to you about Warranted Services that may be of interest to you
                </td>
                <td>
                  Your name and email address
                </td>
                <td>
                  Consent, when required<br />Necessary for our legitimate interests in providing the Warranted Services and promoting our business
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
              </tr>
            </tbody>
          </table>
        </figure>
        <p>
          <br /><strong>16. Changes to this Policy</strong>
        </p>
        <p>
          Because our business needs may change over time, we reserve the right to modify this Privacy Policy. If at any time in the future we plan to use your Personal Data in a way that differs from this Privacy Policy, we will revise this Privacy Policy as appropriate. In the event of a change to our Privacy Policy, we will email the updated policy to the email address that you provided to us. Your continued use of the Warranted Services following our notice of changes to this Privacy Policy means you accept such changes.
        </p>
        <p>
          <strong><br /></strong><strong>17. Our Contact Information</strong>
        </p>
        <p>
          If you have any questions or concerns about this Privacy Policy, please contact our Data Protection Officer via e-mail at <a className='link' href="mailto:dataprotection@warranted.io">dataprotection@warranted.io</a>.
        </p>
        <p>
          <br/>Copyright © Warranted LLC. All rights reserved. The Website is the property of Warranted LLC. and is protected by United States and international copyright, trademark, and other applicable laws. This includes the content, appearance, and design of the Warranted Services, as well as the trademarks, product names, graphics, logos, service names, slogans, colors, and designs.
        </p>
      </div>
      <Footer />
    </div>
  );
}