import React, { useEffect } from 'react';
import {
  Link,
  useLocation,
  useNavigation,
} from 'react-router-dom';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Logo from '../components/Logo';

export default function Terms() {
  const navigation = useNavigation();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div
      id="home"
      className={navigation.state === "loading" ? "loading" : ""}
    >
      <Header />
      <div id='legal'>
        <center>
          <Link to='/' className='link'>
            <Logo />
          </Link>
        </center>
        <p>
          Effective Date: May 20, 2024
        </p>
        <p>
          <b>
            THIS AGREEMENT CONTAINS A MANDATORY ARBITRATION PROVISION AND WAIVER OF JURY TRIAL APPLICABLE TO PERSONS AND ENTITIES WHO ARE NOT GOVERNMENT USERS. PLEASE READ IT CAREFULLY BEFORE AGREEING AND BEFORE USING OUR WEBSITE OR ANY MOBILE APPLICATIONS.
          </b>
        </p>
        <p>
          The materials on this website are provided by Warranted LLC. (hereinafter, “ Warranted ,” “ we ” or “ us ”). The Warranted website at www.warranted.io , the website at app.warranted.io , and certain other sites operated by Warranted where these Terms of Use are posted (including all content posted at those sites) is owned by Warranted LLC., and each is referred to herein as the “ Website.” The services that we may provide to you via the Website or any mobile or desktop application (“ App ”), as well as the Website and App itself, are referred to collectively herein as the “ Warranted Services. ” In general terms, the Warranted Services provide to registered users a platform (the “ Warranted Platform ”) which enables the exchange of information between Business Users and Government Users, as such terms are defined below.
        </p>
        <p>
          By accessing the Website or App or using any of the Warranted Services, you are agreeing to be legally bound by the terms and conditions of this Agreement. Use of and access to the Warranted Services, including the Warranted Platform, is subject to the terms of this Agreement and all applicable laws and regulations.
        </p>
        <p>
          Please read this Agreement carefully. If you do not agree and consent to this Agreement, please do not use the Warranted Services and do not register for an account on the Warranted Platform. If you are accepting this Agreement on behalf of a legal entity other than yourself as an individual, including a business or a government or government agency, you represent and warrant that you have full legal authority to bind such entity to this Agreement. You and Warranted may be referred to herein individually as a “ Party ” and collectively as the “ Parties.”
        </p>
        <p>
          As used in this Agreement, “ you” :
        </p>
        <p>
          are a “ Visitor ” to or when you browse our Website or App without registering to become a Business User or Government User on the Warranted Platform;
        </p>
        <p>
          or are a “ Business User ” who uses the Warranted Platform on behalf of any business or non-profit entity with whom Warranted has a direct or indirect commercial relationship (the “ Business Services ”);
        </p>
        <p>
          or are a “ Government User ” who uses the Warranted Platform on behalf of a police department, government administrative or investigative body, legislative or parliamentary body, or other agency of a national, regional, state/provincial, or municipal authority or body (the “ Government Services ”).
        </p>
        <p>
          Different provisions of these terms will apply to you dependent on if you are a Visitor or a Business or Government User. When we use “you” without expressly stating if this applies to Visitors or Business or Government Users, then that the provision applies to everyone.
        </p>
        <p>
          Business Services, as well as other terms and conditions applicable to Business Users, will
        </p>
        <p>
          be detailed in a User Agreement (“ Business Agreement ”). If there is any conflict between the
        </p>
        <p>
          terms of this Agreement and the Business Agreement applicable to a Business User, the
        </p>
        <p>
          terms of the Business Agreement shall apply.
        </p>
        <p>
          Certain Government Users may be subject to other terms and conditions which will be detailed in a Government Agreement (“ Government Agreement ”). If there is any conflict between the terms of this Agreement and the Government Agreement applicable to a Government User, the terms of the Government Agreement shall apply.
        </p>
        <p>
          <b>
            1.  IMPORTANT NOTICES
          </b>
        </p>
        <p>
          (a) By using and/or visiting the Website, App, or other Warranted Services, you represent that you have read, understand, and agree to all the terms and conditions of this Agreement, including our Privacy Policy (“Privacy Policy”) which is incorporated herein by reference. This Agreement and its Privacy Policy are subject to the provisions of the European Union (“EU”) General Data Protection Regulation (“GDPR”), the California Consumer Privacy Act (“CCPA”) and other applicable privacy laws. Under the definitions used in the GDPR, if you are a Visitor, or a Government User without a separate written contract providing other terms, we are a data “Controller” and you are a “Data Subject” with certain protected privacy rights concerning your “Personal Data.” Personal Data may also be referred to as Personally Identifiable Information (“PII”).
        </p>
        <p>
          (b) We reserve the right to change, modify, add to, or otherwise alter this Agreement at any time, or to change or discontinue any aspect or feature of the Warranted Services (including features of the Warranted Platform) without notice to you. Such changes, modifications, additions or deletions shall be effective immediately upon activation by Warranted. You agree to review this Agreement periodically to be aware of such revisions. Your use of the Warranted Services after we post such changes, modifications, additions or deletions constitutes your acceptance of such changes, modifications, additions or deletions. Notwithstanding the foregoing, if you have provided your email address to us we will notify you via email regarding any changes in the Privacy Policy.
        </p>
        <p>
          (c) We may further:  (i) change Warranted Platform access and use procedures, documentation, security procedures and equipment standards, (ii) change the type and location of our system equipment, facilities or software, or (iii) modify or withdraw any Warranted Services component, or any of our databases, materials, products or systems. We reserve the right to terminate access to the Website, any or all Warranted Services, or take any other actions we reasonably believe necessary to comply with the law or protect our rights or those of our Visitors and users.
        </p>
        <p>
          (d) THIS AGREEMENT CONTAINS A BINDING AND FINAL ARBITRATION PROVISION AND CLASS ACTION WAIVER. WHERE PERMITTED BY LAW, YOU UNDERSTAND AND AGREE TO SUBMIT TO ARBITRATION PROCEEDINGS TO SETTLE ANY DISPUTES HEREUNDER, THAT SUCH ARBITRATION WILL BE IN LIEU OF LITIGATION, AND EACH PARTY HEREBY WAIVES THE RIGHT TO SUE IN COURT OR HAVE A JURY TRIAL IN FAVOR OF THE ARBITRATION PROCEEDING EXCEPT AS PERMITTED UNDER THIS AGREEMENT.
        </p>
        <p>
          <b>
            2. LICENSE GRANT; LIMITATIONS
          </b>
        </p>
        <p>
          (a) Subject to the terms of this Agreement (and to any applicable Business Agreement), we hereby grant you a limited, terminable, non-sublicensable, non-transferable, non-exclusive right to access and use the Warranted Services that are intended for public display or access. Any rights not explicitly granted in this Agreement are strictly withheld and reserved by us. Any access or attempt to access or use the Warranted Services, including the Warranted Platform, for any unauthorized or illegal purpose is strictly prohibited.
        </p>
        <p>
          (b) You agree that (i) except in your normal use of the Website or Warranted Services, you will
        </p>
        <p>
          not copy or distribute any part of the Website or Warranted Services in any medium without our prior written authorization; (ii) you will not alter or modify any part of the Warranted Services other than as is necessary to use the Warranted Services for their intended purposes; and (iii) you will otherwise comply with this Agreement.
        </p>
        <p>
          (c) We shall have no obligation to support your use of the Warranted Services in the event that: (i) you modify the Warranted Services (or any component thereof) without our prior written consent; (ii) you experience any error caused in whole or in part by persons other than us (including without limitation, your failure to properly enter, transmit or receive data); or (iii) you experience any error caused in whole or in part by your use of the Warranted Services in association with operating environments and platforms other than those that we support.
        </p>
        <p>
          (d) To the extent that the Warranted Services incorporate any third-party products, then, in addition to the terms set forth herein, you must comply with any additional terms, restrictions or limitations applicable to such third-party products. We have the right to subcontract performance of hosting and other services, in which event the service levels provided by the applicable third-party providers will be incorporated herein by reference.
        </p>
        <p>
          (e) You shall procure, install and maintain all client-side equipment, data plans, Internet connections and other hardware necessary for you to connect to and access the Warranted Services. We are not responsible for equipment defects, lack of service, or other issues arising from third party services or equipment. You are responsible for all applicable data plan fees, subscription charges or other fees of any kind whatsoever that may be required by your carrier in order to access the Warranted Services.
        </p>
        <p>
          (f) You must not use any part of the content on our Website for commercial purposes without obtaining a license to do so from us. For Business Users, the right to use the Business Services for the commercial purpose of the Business are set out in the Business Agreement
        </p>
        <p>
          (g) If you are a Business User or a Government User, you are responsible for ensuring that any and all persons who access Warranted Services through your internet connection, or otherwise with your permission or authority, are aware of these terms and other applicable terms and conditions, and that they also comply with them in full.
        </p>
        <p>
          <b>
            3. ELIGIBILITY
          </b>
        </p>
        <p>
          (a) Some parts of the Website and/or Warranted Services are not available to the general public, and the Warranted Platform is available only to eligible Business Users and Government User. We may impose and amend eligibility requirements and rules from time to time.
        </p>
        <p>
          (b) You are not eligible to use any Warranted Services if doing so would violate any applicable law or regulation, including but not limited to U.S. export controls, sanctions, or other restrictions.
        </p>
        <p>
          (c) You must be over the age of 18 to register an account on the Website or use the Warranted Services. By registering an account, you represent that you meet this minimum age requirement. In any case, you affirm that you are over the age of 13, as the Warranted Services are not intended for use by children under 13.
        </p>
        <p>
          (d) If you are under 13 years of age, then please do not use the Website without the consent of your parent or guardian. Pursuant to 47 U.S.C. Section 230(d), as amended, we hereby notify you that parental control protections (such as computer hardware, software or filtering services) are commercially available to assist you in limiting access to material that is harmful to minors. More information on the availability of such software can be found through publicly available sources and your internet service provider.
        </p>
        <p>
          <b>
            4. TERMS APPLICABLE TO GOVERNMENT USERS
          </b>
        </p>
        <p>
          (a) Certain Warranted Services are not available to Visitors or Business Users, but will be made available to Government Users.
        </p>
        <p>
          (b) Government Users will be required to register an account with us, and we will ask you to provide us with certain credentials or other login information (“ Credentials ”). In doing so, you agree that you will provide accurate and complete information.
        </p>
        <p>
          (c) We may refuse to process your Credentials or any documents or transactions you seek to transmit or receive through the Warranted Service if we believe that you may be: (i) impersonating another person or falsely claiming association with a government agency; (ii) violating the intellectual property or other rights of any entity; (iii) violating the rights of any person or entity through unauthorized Submissions or other use of the Warranted Services; (iv) providing any information that we may otherwise believe to be false; or (v) for any or no reason in our sole discretion.
        </p>
        <p>
          (d) You are under no obligation to provide Credentials to us, however, if you do, you represent and warrant that you are authorized to provide these Credentials for use with the Warranted Services, and that the Credentials are and will be true and accurate throughout the Term of this Agreement. By providing your Credentials, you agree that we may store and use the Credentials in accordance with our Privacy Policy.
        </p>
        <p>
          (e) Once you have a Government User account, you agree to keep your user name and password and/or any other Credentials needed to login to the Warranted Platform confidential and secure. You are solely responsible for controlling the access to and use of your account. You understand and agree that we assume that instructions we receive from your account are authoritative, and that we should act upon such instructions. We are not responsible for any unauthorized access to your account or profile or the ramifications of such access, and we are not required to take action to disable any account. You agree that you will not bring any action against us arising out of or related to any claimed unauthorized access using your account Credentials.
        </p>
        <p>
          (f) Notwithstanding the foregoing, if we believe that there has been an unauthorized access to your Warranted Platform account, we may take reasonable actions to disable or lock your account, or otherwise address your situation. You will not be able to access information sent to you by Business Users if you lose access to your account for any reason whatsoever.
        </p>
        <p>
          <b>
            5. RESTRICTIONS
          </b>
        </p>
        <p>
          (a) In connection with your use of the Website or App and all other Warranted Services, including the Warranted Platform, you agree that you will not violate any law or regulation which may be applicable to you or to the data you request, provide, or receive.
        </p>
        <p>
          (b) You agree that you will not distribute, upload, make available or otherwise publish through any Warranted Services any registration information, requests for information or data, or any suggestions, information, ideas, comments, causes, promotions, documents, questions, notes, plans, drawings, proposals, graphics, text, information, links, profiles, personal information, name, likeness, audio, photos, software, music, sounds, video, comments, messages, posts, tags or similar materials (“Submissions”) that:
        </p>
        <p>
          ● are unlawful or encourage or induce another to engage in anything unlawful;
        </p>
        <p>
          ● contain a virus or any other similar malicious software that may damage the operation of our or another’s computers;
        </p>
        <p>
          ● infringe upon any copyright, patent, trademark, trade secret, right of privacy, right of publicity or other right of any person or entity;
        </p>
        <p>
          ● are false, inaccurate, fraudulent or misleading; or
        </p>
        <p>
          ● are libelous, defamatory, obscene, inappropriate, abusing, harassing, threatening or bullying.
        </p>
        <p>
          (c) You further agree that you will not do any of the following:
        </p>
        <p>
          ● modify, adapt, translate, copy, reverse engineer, decompile or disassemble any portion of the Warranted Services;
        </p>
        <p>
          ● interfere with or disrupt the operation of the Website, App, or any Warranted Services, including restricting or inhibiting any other person from using
          the same by means of hacking or defacing;
        </p>
        <p>
          ● transmit to or make available in connection with any Warranted Services any denial-of-service attack, virus, worm, Trojan horse or other harmful code
          or activity;
        </p>
        <p>
          ● attempt to probe, scan or test the vulnerability of any Warranted Services or breach our security or authentication measures;
        </p>
        <p>
          ● take any action that imposes an unreasonable or disproportionately large load on our infrastructure, as determined in our sole discretion;
        </p>
        <p>
          ● harvest or collect the email addresses or other Personal Data of other users of the Warranted Services;
        </p>
        <p>
          ● submit or post false, incomplete, or misleading information, or otherwise provide such information to us; or
        </p>
        <p>
          ● impersonate any other person, entity, business, or governmental entity.
        </p>
        <p>
          (d) You will not engage in text or data mining, or web scraping. This clause shall not apply insofar as (but only to the extent that) we are unable to exclude or limit text or data mining or web scraping activity by contract under the laws which are applicable to us. You shall not conduct, facilitate, authorize or permit any text or data mining or web scraping in relation to our Website or any Warranted Services provided via, or in relation to, our Website. This includes using (or permitting, authorizing or attempting the use of):
        </p>
        <p>
          ● Any “robot”, “bot”, “spider”, “scraper” or other automated device, program, tool, algorithm, code, process or methodology to access, obtain, copy,
          monitor or republish any portion of the Website or any data, content, information or services accessed via the same.
        </p>
        <p>
          ● Any automated analytical technique aimed at analyzing text and data in digital form to generate information which includes but is not limited to
          patterns, trends and correlations.
        </p>
        <p>
          ● The provisions in this clause should be treated as an express reservation of our rights in this regard, including for the purposes of Article 4(3) of
        </p>
        <p>
          Digital Copyright Directive ((EU) 2019/790).
        </p>
        <p>
          (e) We reserve the right to review, edit, block, or remove Submissions. Exchanges between Business Users and Government Users are not reviewed by us under ordinary circumstances, except that upon request and at the direction of specific Business Users, we may take commercially reasonable automated steps to screen Submissions for malicious code. Otherwise, we are not required and do not undertake to screen or monitor any or all Submissions to the Warranted Services. or as may be provided through or on the Warranted Platform. YOU AGREE THAT WE ARE NOT RESPONSIBLE FOR ANY SUCH SUBMISSIONS, AND YOUR RELIANCE ON ANY INFORMATION CONTAINED THEREIN IS AT YOUR OWN RISK.
        </p>
        <p>
          (f) You agree that you are not licensed or permitted to access any portion of the Warranted Services that we have not made public or accessible to users (whether registered or not), and you may not attempt to override any security measures we have in place.
        </p>
        <p>
          (g) Notwithstanding the foregoing rules of conduct, our unlimited right to terminate your access to the Warranted Services shall not be limited to violations of this Restrictions section.
        </p>
        <p>
          <b>
            6. SUBMISSIONS
          </b>
        </p>
        <p>
          (a) You are under no obligation to submit anything to us, or to use Warranted Services. We will not claim ownership of your Submissions. In order for us to provide the Warranted Services to you, however, we require your permission to transmit, process, display, reproduce and otherwise use Submissions you make available to us. Therefore, if you choose to make any Submissions (including your name, email address or other Personal Data) to us, or otherwise make any Submissions available through the Warranted Services, you hereby grant to us a perpetual, irrevocable, transferrable, sub-licensable, non-exclusive, worldwide, royalty-free license to reproduce, use, modify, display, perform, distribute, transmit, translate and create derivative works from any such Submissions for purposes of providing, using, and improving the Warranted Services. Please note that we make commercially reasonable efforts to transmit encrypted documents through the Warranted Platform between Business Users and Government Users, as designated by those users, and do not seek access to the contents of such documents as part of our normal operations. Please note further that certain Business Users may request, as part of the Warranted Services provided to them, automated screening of Submissions for malicious code.
        </p>
        <p>
          (b) Notwithstanding the foregoing grant, as further identified in the Privacy Policy, Personal Data that you upload or make available for the purpose of using the Warranted Services will only be used by us as stated in the Privacy Policy.
        </p>
        <p>
          (c) By submitting any Submissions to us, you hereby agree, warrant and represent that: (a) your provision of the Submissions does not violate any third party’s rights (including without limitation, any target of any investigation); (b) all such Submissions are accurate and true; and (c) you are not entitled to compensation or attribution from us. 
        </p>
        <p>
          (d) You acknowledge that we are under no obligation to maintain any Submissions that you submit, post or make available to the Warranted Services. Except as may be modified by a written agreement with a Business User, we reserve the right to withhold, remove and or discard any such materials at any time.
        </p>
        <p>
          <b>
            7. ELECTRONIC COMMUNICATIONS; TRANSACTIONS
          </b>
        </p>
        <p>
          Because we operate the Warranted Services on the internet, you consent to transact business with us electronically in order to use the Warranted Services. As part of such business, you also consent to our communicating information to you electronically. Under this Agreement, you agree to electronically receive all documents, communications, notices, disclosures, contracts, and agreements arising from or relating to your use of the Warranted Services, including without limitation your registration to use the Warranted Platform through our Website or App (each, a “ Disclosure ”). We will provide all Disclosures to you electronically through the Warranted Services or via electronic mail to the email address you provided.
        </p>
        <p>
          <b>
            8. INFORMATION SHARED THROUGH THE WARRANTED SERVICES
          </b>
        </p>
        <p>
          You understand that by sharing information with us or with others via the Warranted Services, and by requesting information to be sent through the Warranted Services, you may be revealing information about yourself, your business, your governmental affiliation, or the target of law enforcement or investigative activities, all of which you may include or that may be generated by the Warranted Services in connection with your Submission. You understand and acknowledge that you are fully aware and responsible for the impact of sharing such materials, and you agree that we are not responsible or liable in any way in connection with such sharing.
        </p>
        <p>
          <b>
            9. LINKS TO THIRD PARTY WEBSITES
          </b>
        </p>
        <p>
          For your convenience, the Warranted Services may contain links to the websites of third parties from which you may be able to obtain information or use services. Except as otherwise noted, such third-party websites, and such information and services are provided by organizations that are independent of us. We do not make any representations or warranties concerning such websites. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party websites. In addition, we cannot censor or edit the content of any third-party site. Therefore, we make no representation as to the accuracy or any other aspect of the information contained in or on such websites, sources or servers. Any linking to or from any such off-site pages or other websites by you is at your own risk. By using the Warranted Services, you expressly relieve us from any and all liability arising from your use of any third-party website. Accordingly, we encourage you to be aware when you leave the Warranted Services, and to read the agreements and privacy policy of each other website that you visit.
        </p>
        <p>
          <b>
            10. OUR INTELLECTUAL PROPERTY
          </b>
        </p>
        <p>
          (a) Our graphics, logos, names, designs, page headers, button icons, scripts and service names are our trademarks, trade names and/or trade dress. The “look and feel” of the Warranted Services (including color combinations, button shapes, layout, design and all other graphical elements) are protected by international copyright and trademark laws. All product names, services names, trademarks and service marks within the Warranted Services (“Marks ”) are either our property or the property of their respective owners, as indicated. You may not use the Marks for any purpose whatsoever other than as permitted by this Agreement.
        </p>
        <p>
          (b) You acknowledge that (i) the software used to provide the Warranted Services (including the Warranted Platform), and all enhancements, updates, upgrades, corrections and modifications to such software (the “ Software ”), (ii) all copyrights, patents, trade secrets, or trademarks or other intellectual
        </p>
        <p>
          property rights protecting or pertaining to any aspect of the Software (or any enhancements, corrections or modifications) and (iii) all documentation therefor, are the sole and exclusive property of us and/or our licensors. This Agreement does not convey title or ownership to you, but instead gives you only the limited use rights set forth herein. To the extent that you gain access to or receive any copies of the Software, you agree that you will delete such copies of the Software upon any termination of this Agreement, termination of your use of the Warranted Services, or at our request.
        </p>
        <p>
          (c) You acknowledge our proprietary rights in the Warranted Services and associated documentation and materials, and shall protect the proprietary nature thereof. If you suggest any new features, functionality or performance for the Warranted Services that we subsequently incorporate into the
        </p>
        <p>
          Warranted Services (or any other software or service), you hereby acknowledge that (i) we shall own, and have all rights to use such suggestions and the Warranted Services incorporating such new features, functionality or performance; and (ii) all such suggestions shall be free from any confidentiality restrictions that might otherwise be imposed upon us. You shall not sell, lease, or otherwise transfer or distribute the Warranted Services or associated documentation, in whole or in part, without prior authorization in writing from us. In the event of any breach of this paragraph, you agree that we will suffer irreparable harm and shall therefore be entitled to obtain injunctive relief against you.
        </p>
        <p>
          <b>
            11. DISCLAIMERS
          </b>
        </p>
        <p>
          (a) We do not represent or warrant that access to the Website, any App, or any other Warranted Services (including the Warranted Platform) will be error-free or uninterrupted, and we do not guarantee that users will be able to access or use the Warranted Services, or their features at all times. We reserve the right at any time to modify or discontinue (temporarily or permanently) the Warranted Services, or any part thereof, with or without notice.
        </p>
        <p>
          (b) Certain data displayed by the Warranted Services rely on the receipt of underlying data from third-party sources. This includes, but is not limited to, Submissions of Business Users and Government Users. Such data sources may not be real-time or accurate, and there may be delays or inaccuracies in such displayed data. We are not responsible for any such delays or errors.
        </p>
        <p>
          (c) The Warranted Services may also contain typographical errors or inaccuracies, and may not be complete or current. We reserve the right to correct any such errors, inaccuracies or omissions, and to change or update information and Disclosures at any time without prior notice.
        </p>
        <p>
          (d) Although we have the right to review, edit, remove or modify certain information from or on the Warranted Services, we may not screen this material or control the sources of this information, and we do not guarantee the accuracy, suitability, completeness, currency, quality, adequacy or applicability of any such information. Furthermore, due to the continuously evolving nature of malware and similar threats, we do not warrant the accuracy or completeness of any automated malware screening processes we may use at the request of certain Business Users.
        </p>
        <p>
          (e) The materials displayed by the Warranted Services, including but not limited to requests made by users, as well as our own summaries, descriptions, publications and any other such materials, are not intended to and DO NOT constitute legal, financial, investment, business or professional advice of any kind. Those accessing or receiving any materials displayed by the Warranted Services should not act upon them without first seeking relevant professional counsel. The materials should not be used as a substitute for consultation with a professional adviser. You agree that we are not responsible for any financial, business or legal decisions that you may make. Circular 230 Disclosure: Pursuant to U.S. Treasury Department Regulations, we are required to advise you that, unless otherwise expressly indicated, any federal tax advice contained in the Warranted Services, including attachments and enclosures, is not intended or written to be used, and may not be used, for the purpose of (i) avoiding tax-related penalties under the Internal Revenue Code or (ii) promoting, marketing or recommending to another party any tax-related matters addressed herein.
        </p>
        <p>
          (f) BY USING THE WARRANTED SERVICES YOU AGREE AND ACKNOWLEDGE THAT WE PROVIDE THE WARRANTED SERVICES “AS IS” WITHOUT ANY WARRANTIES, EXPRESS, IMPLIED OR STATUTORY. WE AND OUR PARENTS, SUBSIDIARIES, OFFICERS, DIRECTORS, SHAREHOLDERS, MEMBERS, MANAGERS, EMPLOYEES AND SUPPLIERS, SPECIFICALLY DISCLAIM ANY IMPLIED WARRANTIES OF TITLE, ACCURACY, SUITABILITY, APPLICABILITY, MERCHANTABILITY, PERFORMANCE, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT OR ANY OTHER WARRANTIES OF ANY KIND. NO ADVICE OR INFORMATION (ORAL OR WRITTEN) OBTAINED BY YOU FROM US SHALL CREATE ANY WARRANTY.
        </p>
        <p>
          (g) USE OF THE WARRANTED SERVICES IS AT YOUR SOLE RISK. WE DO NOT WARRANT THAT YOU WILL BE ABLE TO ACCESS OR USE THE WARRANTED SERVICES AT THE TIMES OR LOCATIONS OF YOUR CHOOSING; THAT THE WARRANTED SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE; THAT DEFECTS WILL BE CORRECTED; OR THAT THE WARRANTED SERVICES ARE FREE OF INACCURACIES, MISREPRESENTATIONS BY USERS, VIRUSES OR OTHER HARMFUL COMPONENTS.
        </p>
        <p>
          (h) YOU ASSUME SOLE RESPONSIBILITY TO TAKE ADEQUATE PRECAUTIONS AGAINST DAMAGE TO YOUR EQUIPMENT WHICH COULD BE CAUSED BY DEFECTS OR DEFICIENCIES IN THE WARRANTED SERVICES, ANY OF OUR INFORMATION, ANY INFORMATION OR DATA YOU RECEIVE, OR ANY PRODUCTS, SYSTEMS, DATABASES, MATERIALS, OR PART THEREOF.
        </p>
        <p>
          (i) YOU ACKNOWLEDGE THAT ELECTRONIC COMMUNICATIONS AND DATABASES ARE SUBJECT TO ERRORS, TAMPERING AND BREAK-INS AND THAT WE DO NOT GUARANTEE THAT SUCH EVENTS WILL NOT TAKE PLACE. YOUR INSTALLATION AND INPUTS, AS WELL AS THIRD-PARTY SYSTEMS AND PROCEDURES, MAY INFLUENCE THE OUTPUT AND ERRORS IN ANY ORDER OR ELECTRONIC TRANSMISSION OR COMMUNICATION, AND CAN RESULT IN SUBSTANTIAL ERRORS IN OUTPUT, INCLUDING INCORRECT INFORMATION AND DOCUMENTS. IN ADDITION, ERRORS MAY BE INTRODUCED INTO INFORMATION OR DOCUMENTS IN THE COURSE OF THEIR TRANSMISSION OVER ELECTRONIC NETWORKS.
        </p>
        <p>
          <b>
            12. LIMITATION OF LIABILITY
          </b>
        </p>
        <p>
          (a) TO THE MAXIMUM EXTENT PERMITTED BY LAW, AND EXCEPT AS OTHERWISE PROHIBITED BY LAW, IN NO EVENT SHALL WE OR OUR AFFILIATES, LICENSORS OR BUSINESS PARTNERS (COLLECTIVELY, THE “RELATED PARTIES”) BE LIABLE TO YOU BASED ON OR RELATED TO ANY OF THE WARRANTED SERVICES, WHETHER BASED IN CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY OR OTHERWISE, AND WE SHALL NOT BE RESPONSIBLE FOR ANY LOSSES OR DAMAGES, INCLUDING WITHOUT LIMITATION DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, PUNITIVE, EXEMPLARY OR SPECIAL DAMAGES ARISING OUT OF OR IN ANY WAY CONNECTED WITH ACCESS TO OR USE OF THE WARRANTED SERVICES, EVEN IF WE AND/OR RELATED PARTIES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
        </p>
        <p>
          (b)  Notwithstanding the foregoing, in the event that a court shall find that any of the above disclaimers are not enforceable, then you agree that neither we nor any of our subsidiaries, affiliated companies, employees, members, shareholders, or directors shall be liable for (i) any damages in excess
        </p>
        <p>
          of the greater of the amounts you have paid to us during the most recent twelve (12) month period or $100.00, or (ii) any indirect, incidental, punitive, special, exemplary or consequential damages or loss of use, lost revenue, lost profits or data to you or any third party from your use of the Warranted Services. This limitation shall apply regardless of the basis of your claim or whether or not the limited remedies provided herein fail of their essential purpose.
        </p>
        <p>
          (c)  SOME JURISDICTIONS MAY NOT PERMIT CERTAIN DISCLAIMERS AND LIMITATIONS, AND ANY SUCH DISCLAIMERS OR LIMITATIONS ARE VOID WHERE PROHIBITED.
        </p>
        <p>
          (d) Our liability if you are a consumer based in the United Kingdom:
        </p>
        <p>
          ● This Section 12(d) applies only to consumers based in the United Kingdom and not to any other persons or users. It does not apply to Business
          Users or Government Users.
        </p>
        <p>
          ● We do not exclude or limit in any way our liability to you where it would be unlawful to do so. This includes liability for death or personal injury
          caused by negligence; for fraud or fraudulent misrepresentation.
        </p>
        <p>
          ● If defective digital content which we have supplied damages a device or digital content belonging to you and this is caused by our failure to use
          reasonable care and skill we will either repair the damage or pay you compensation. However, we will not be liable for damage which you could
          have avoided by following our advice to apply an update offered to you free of charge or for damage which was caused by you
          failing to correctly follow installation instructions or to have in place the minimum system requirements advised by us.
        </p>
        <p>
          ● We are not liable for business losses. We only supply the Website (and not any App or the Warranted Platform) for domestic and private use, unless
          we expressly agree otherwise with you. If you use any Warranted Services for any commercial, business or re-sale purpose we will have no liability to
          you for any loss of profit, loss of business, business interruption, or loss of business opportunity.
        </p>
        <p>
          <b>
            13. INDEMNIFICATION
          </b>
        </p>
        <p>
          (a) You agree to defend, indemnify and hold harmless us and our directors, officers, employees, agents, licensors, advisors, independent
          contractors and providers (collectively referred to herein as “Indemnified Parties”) from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to reasonable attorney’s fees) arising from: (i) your use of and access to the Warranted Services; (ii) your violation of any term of this Agreement; (iii) your violation of any rights of any third party, including without limitation any copyright, property or privacy right; (iv) any allegation that any of your Submissions (whether requested or provided by you or any third party) caused damage to a third party; or (v) any conduct, activity or action that is unlawful or illegal under any national, state, provincial, local, municipal or common law, or is violative of the rights of any individual or entity, engaged in, caused by, or facilitated in any way through the use of the Warranted Services.
        </p>
        <p>
          (b) You further agree that you will cooperate with us as is reasonably required in the defense of any such claims in Section 13(a). We, on behalf of each of the Indemnified Parties, reserve each Indemnified Party’s right, at its own expense, to assume the exclusive defense and control of any matter otherwise subject to your foregoing indemnification obligations, and you shall not, in any event, settle any claim or matter without the written consent of us and each of the Indemnified Parties named as a party in such claim.
        </p>
        <p>
          (c) This defense and indemnification obligation will survive any termination or expiration of this Agreement or your use of the Website and/or any other Warranted Services.
        </p>
        <p>
          <b>
            14. TERM; TERMINATION
          </b>
        </p>
        <p>
          (a) The “ Term ” of this Agreement will continue until the Agreement is terminated as provided herein. We reserve the right to terminate this Agreement and/or deny all or some portion of the Warranted Services to you or any user, in our sole discretion, at any time. This Agreement shall commence upon our providing you with access to the Warranted Services and shall continue until terminated by its terms.
        </p>
        <p>
          (b) You may terminate this Agreement at any time by ceasing use of Website or other Warranted Services. We may terminate this Agreement at any time by denying you access to the Warranted Services. However, termination of Business Services are subject to the provisions of the applicable Business Agreement, and termination of Government Services may be subject to the provisions of a Government Agreement, if applicable.
        </p>
        <p>
          (c) Without limiting the foregoing or assuming any additional legal obligations, we reserve the right to terminate violators of the Copyright Act, in accordance with applicable law. All rights that you grant to us herein related to Submissions shall survive any termination of this Agreement. Further, your representations, warranties and indemnification obligations herein shall survive any termination of this Agreement. 
        </p>
        <p>
          (d) Upon termination of the Agreement for any reason, your right to use the Website, any App, and any other Warranted Services shall immediately cease. Termination of this Agreement shall not relieve either Party of any obligation accrued prior to the termination date or which by its nature should survive any termination of this Agreement.
        </p>
        <p>
          <b>
            15. CONFIDENTIALITY
          </b>
        </p>
        <p>
          (a) The term “Confidential Information” means the Warranted Services, associated documentation, our pricing, and all other information we disclose to you that is designated as confidential or that by its nature would reasonably be expected to be kept confidential.
        </p>
        <p>
          (b) Notwithstanding the previous paragraph, our Confidential Information shall not include information that (i) is or becomes publicly available through no act or omission of yours; or (ii) was in your lawful possession prior to the disclosure and had not been obtained by you either directly or indirectly from us; or (iii) is lawfully disclosed to you by a third party not bound by a duty of non-disclosure; or (iv) is independently developed by you without access to or use of our Confidential Information.
        </p>
        <p>
          (c) You agree to hold all Confidential Information in confidence. You agree not to make the Confidential Information available in any form to any third party or to use the Confidential Information for any purpose other than performing your obligations or enjoying your rights under this Agreement. You agree to use the same degree of care in protecting the Confidential Information that you use to protect confidential information of your own of a similar nature and value, but in no event less than a reasonable standard of care to ensure that Confidential Information is not disclosed or distributed by your employees or agents in violation of the provisions of this Agreement. You represent that you have, with each of your employees who may have access to any Confidential Information, an appropriate agreement sufficient to enable you to comply with all of the confidentiality terms hereof.
        </p>
        <p>
          (d) Notwithstanding the foregoing, you may disclose the Confidential Information to the extent that such disclosure is required by law or court order, provided, however, that you provide us with prior written notice of such disclosure and reasonable assistance in obtaining an order protecting the Confidential Information from public disclosure.
        </p>
        <p>
          (e) After termination or expiration of this Agreement, you shall return any Confidential Information in your possession or control to us.
        </p>
        <p>
          <b>
            16. DISPUTES, GOVERNING LAW AND JURISDICTION
          </b>
        </p>
        <p>
          (a) The laws of the Commonwealth of Massachusetts shall govern this Agreement. Any legal proceeding or arbitration shall be held in Los Angeles, California (the “ Dispute Resolution Location ”). To the extent arbitration does not apply, you agree that any dispute arising out of or relating to the Warranted Services or us, may only be brought by you in a state or federal court located in the Dispute Resolution Location. YOU HEREBY WAIVE ANY OBJECTION TO THIS VENUE AS INCONVENIENT OR INAPPROPRIATE, AND AGREE TO EXCLUSIVE JURISDICTION AND VENUE IN THE DISPUTE RESOLUTION LOCATION.
        </p>
        <p>
          Sections 16(b) through 16(e) apply to Visitors and Business Users only:
        </p>
        <p>
          (b) You agree that any claim or dispute arising out of or relating in any way to your use of the Warranted Services or any other service provided by us, will be resolved solely and exclusively by binding arbitration, rather than in court, except that you may assert claims in small claims court if your claims qualify. The Federal Arbitration Act and federal arbitration law apply to this Agreement. YOU UNDERSTAND AND AGREE TO SUBMIT TO ARBITRATION PROCEEDINGS TO SETTLE ANY DISPUTES HEREUNDER, THAT SUCH ARBITRATION WILL BE IN LIEU OF LITIGATION, AND EACH PARTY HEREBY WAIVES THE RIGHT TO SUE IN COURT IN FAVOR OF THE ARBITRATION PROCEEDING EXCEPT AS PERMITTED UNDER THIS AGREEMENT.
        </p>
        <p>
          (c) There is no judge or jury in arbitration, and court review of an arbitration award is limited. An arbitrator, however, may award on an individual basis the same damages and relief as a court (including injunctive and declaratory relief or statutory damages), and must follow the terms of this Agreement as a court would.
        </p>
        <p>
          (d) To begin an arbitration proceeding, you must send a letter requesting arbitration and describing your claim to our address specified in the Notice section, below. You consent to our delivery to you of any communications or notices we send to your email address as registered with us. 
        </p>
        <p>
          (e) Arbitration under this Agreement will be conducted by the American Arbitration Association (“ AAA ”) under its rules then in effect. Payment of all filing, administration and arbitrator fees will be governed by the AAA’s rules.
        </p>
        <p>
          (f) You agree that any dispute resolution proceedings will be conducted only on an individual basis and not in a class, consolidated or representative action. If for any reason a claim proceeds in court rather than in arbitration, we both agree that we have each waived any right to a jury trial.
        </p>
        <p>
          (g) Notwithstanding the foregoing, you agree that we may bring suit in court to enjoin infringement or other misuse of intellectual property or other proprietary rights.
        </p>
        <p>
          (h) Any dispute or alleged claim you may have with respect to your access or use of the Warranted Services must be commenced within one (1) year after the occurrence of the events leading to the dispute or alleged claim.
        </p>
        <p>
          (i) Nothing in the foregoing shall exclude or restrict rights you have under local law that cannot be excluded or restricted. If you are a consumer, you may be entitled under law to bring claims against Warranted in the courts and pursuant to the governing law of your country of residence.
        </p>
        <p>
          <b>
            17. GENERAL
          </b>
        </p>
        <p>
          (a) Relationship Between The Parties. This Agreement shall not be construed as creating any agency, partnership, joint venture, or other similar legal relationship between the Parties; nor will either Party hold itself out as an agent, partner, or joint venture party of the other Party.
        </p>
        <p>
          (b) Other Agreements. If the terms of this Agreement conflict with the terms of a master services agreement, statement of work or any similar agreement which has been duly executed by us and which governs the relationship between us and you, as a specific Business User or a specific Government User, the terms of such specific agreement shall prevail to the extent of such conflict.
        </p>
        <p>
          (c) Compliance With Law. Each Party shall comply with all applicable laws and regulations of governmental bodies or agencies in its performance under this Agreement.
        </p>
        <p>
          (d) Waiver. No waiver shall be implied from conduct or failure to enforce rights. No waiver shall be effective unless in a writing signed by both Parties.
        </p>
        <p>
          (e) Severability. If any provision of this Agreement is held to be invalid, void or unenforceable, such provision shall be deemed to be restated to reflect as nearly as possible the original intentions of the Parties in accordance with applicable law, and the remaining provisions of this Agreement shall remain in full force and effect.
        </p>
        <p>
          (f) Assignment. We may assign our rights under this Agreement, in whole or in part, to any person or entity at any time with or without your consent. You may not assign the Agreement or delegate any of your rights, interest or obligations hereunder, without our prior written consent. Any unauthorized assignment shall be null and void. This Agreement shall bind and inure to the benefit of the Parties and
        </p>
        <p>
          their respective successors and permitted assigns.
        </p>
        <p>
          (g) Force Majeure. We shall not be in default or otherwise liable for any delay in or failure of our performance under this Agreement where such delay or failure arises by reason of any Act of God, or any government or any governmental body, war, insurrection, acts of terrorism, the elements, strikes or labor disputes, or other similar or dissimilar causes beyond our control. You acknowledge that the performance of certain of our obligations may require the cooperation of third parties designated by you and outside our control. In the event such third parties fail to cooperate with us in a manner that reasonably permits us to perform our obligations, such failures shall be consider as causes beyond our reasonable control for the purposes of this Section, and shall not be the basis for a determination that we are in breach of any of our obligations under this Agreement or are otherwise liable.
        </p>
        <p>
          (h) Equitable Remedies. You hereby agree that we would be irreparably damaged if the terms of this Agreement were not specifically enforced, and therefore you agree that we shall be entitled, without bond, other security, or proof of irreparable harm or other damages, to appropriate equitable remedies with respect to breaches of this Agreement, in addition to such other remedies as we may otherwise have available to us under applicable laws. 
        </p>
        <p>
          (i) Entire Agreement. This Agreement shall constitute the complete agreement between the Parties and supersedes all previous agreements or representations, written or oral, with respect to the subject matter hereof.
        </p>
        <p>
          (j) Notices and Contact. All legal notices given by you or required under this Agreement shall be in writing and addressed to: Warranted LLC., legal@warranted.io. You may also contact us with general inquiries at Email: support@warranted.io.
        </p>
        <p>
          (k) Survival. Any provision of this Agreement that may reasonably be interpreted as being intended by the Parties to survive termination or expiration of the Agreement, shall survive any such termination or expiration.
        </p>
        <p>
          Copyright © Warranted LLC. All rights reserved. The Website is protected by United States and international copyright, trademark, and other applicable laws. This includes the content, appearance, and design of the Website, any App, the Warranted Platform, or any other Warranted Service, as well as the trademarks, product names, graphics, logos, service names, slogans, colors, and designs.
        </p>
      </div>
      <Footer />
    </div>
  );
}