import React from 'react';
import {
  Link,
  NavLink,
} from 'react-router-dom';
import {
  IoOpenOutline,
} from 'react-icons/io5';
import Logo from './Logo';

export default function Footer() {

    return (
        <div className='footer'>
            <Logo light inline/>
            <div className='contact'>
                Contact
                <div className='email'>
                    hi@warranted.io
                </div>
            </div>
            <div className='center-divider'></div>
            <div className='libraries'>
                Client Libraries
                <div>
                    <Link to='https://github.com/warranted-io/warranted-go' target='_blank' className='link'>
                        Go <IoOpenOutline />
                    </Link>
                </div>
                <div>
                    <Link to='https://github.com/warranted-io/warranted-java' target='_blank' className='link'>
                        Java <IoOpenOutline />
                    </Link>
                </div>
                <div>
                    <Link to='https://github.com/warranted-io/warranted-node' target='_blank' className='link'>
                        Node JS <IoOpenOutline />
                    </Link>
                </div>
                <div>
                    <Link to='https://github.com/warranted-io/warranted-python' target='_blank' className='link'>
                        Python <IoOpenOutline />
                    </Link>
                </div>
            </div>
            <div className='copywrite'>
                © {(new Date()).getFullYear()} Warranted LLC | All rights reserved.
                <div className='links'>
                    <NavLink to='/terms' className='link'>
                        Terms of Use
                    </NavLink>
                    <NavLink to='/privacy-policy' className='link'>
                        Privacy Policy
                    </NavLink>
                    <NavLink to='/privacy-policy' className='link'>
                        Cookies
                    </NavLink>
                </div>
            </div>
        </div>
    );
}